import styled from 'styled-components';

export const Container = styled.div<{
  color: string;
  variant: 'regular' | 'small';
  hasTooltip?: boolean;
  onClick?: () => void;
}>`
  display: inline-block;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1;
  color: ${({ theme }) => theme.color.pinkDark1};
  background: ${({ theme }) => theme.color.main}20;

  ${({ variant }) =>
    variant === 'small' &&
    `
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 10px;
  `}

  ${({ theme, color }) =>
    color === 'gray' &&
    `
    color: ${theme.color.textGrayscale3};
    background: ${theme.color.textGrayscale3}20;
  `}

  ${({ theme, color }) =>
    color === 'red' &&
    `
    color: ${theme.color.redDark1};
    background: ${theme.color.red}20;
  `}

  ${({ theme, color }) =>
    color === 'blue' &&
    `
    color: ${theme.color.blueDark1};
    background: ${theme.color.blue}20;
  `}

  ${({ theme, color }) =>
    color === 'yellow' &&
    `
    color: ${theme.color.yellowDark2};
    background: ${theme.color.yellow}50;
  `}

  ${({ color }) =>
    color === 'zapier' &&
    `
    color: #ff4a00;
    background: #ff4a0020;
  `}

  ${({ theme, color }) =>
    color === 'green' &&
    `
    color: ${theme.color.greenDark1};
    background: ${theme.color.green}20;
  `}

  ${({ theme, color }) =>
    color === 'accent' &&
    `
    color: ${theme.color.accent};
    background: ${
      theme.color.isBackgroundDark ? `${theme.color.accent}50` : `${theme.color.accent}20`
    };
  `}

  ${({ hasTooltip }) =>
    hasTooltip &&
    `
    cursor: default;
  `}

  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
  `}
`;
