import { ReactNode } from 'react';

import Tooltip from '@/components/tooltip';

import { Container } from './styled';

interface Props {
  children: ReactNode;
  color?: 'main' | 'gray' | 'red' | 'blue' | 'yellow' | 'zapier' | 'green' | 'accent';
  variant?: 'regular' | 'small';
  tooltip?: string;
  onClick?: () => void;
}

const Badge = ({ children, color = 'main', variant = 'regular', tooltip, onClick }: Props) => {
  return (
    <Container
      className="badge"
      color={color}
      variant={variant}
      hasTooltip={!!tooltip}
      onClick={onClick}>
      {children}

      {!!tooltip && <Tooltip text={tooltip} highlightWords={tooltip.includes('<em>')} />}
    </Container>
  );
};

export default Badge;
