export enum Event {
  Registration = 'REGISTRATION',
  Login = 'LOGIN',
  LoggedInSession = 'LOGGED_IN_SESSION',
  FormBuilderView = 'FORM_BUILDER_VIEW',
  FormBuilderDesignView = 'FORM_BUILDER_DESIGN_VIEW',
  FormInsertBlock = 'FORM_INSERT_BLOCK',
  FormInsertSource = 'FORM_INSERT_SOURCE',
  FormHeaderAction = 'FORM_HEADER_ACTION',
  FormWelcomeStart = 'FORM_WELCOME_START',
  FormWelcomePost = 'FORM_WELCOME_POST',
  BlocksSelectAction = 'BLOCKS_SELECT_ACTION',
  PublishForm = 'PUBLISH_FORM',
  FormShareCopyLink = 'FORM_SHARE_COPY_LINK',
  FormEmbedClick = 'FORM_EMBED_CLICK',
  SidebarResourceClick = 'SIDEBAR_RESOURCE_CLICK',
  HelpResourceClick = 'HELP_RESOURCE_CLICK',
  IntegrationClick = 'INTEGRATION_CLICK',
  CreateWorkspace = 'CREATE_WORKSPACE',
  CreateTemplate = 'CREATE_TEMPLATE',
  AddDomain = 'ADD_DOMAIN',
  InvitedMembers = 'INVITED_MEMBERS',
  SubscriptionPaywall = 'SUBSCRIPTION_PAYWALL',
  SubscriptionCheckout = 'SUBSCRIPTION_CHECKOUT',
  SubscriptionPaid = 'SUBSCRIPTION_PAID',
  PoweredByBadge = 'POWERED_BY_BADGE',
  BulkInsertOptions = 'BULK_INSERT_OPTIONS',
  ColumnLayoutAction = 'COLUMN_LAYOUT_ACTION',
  KeyboardShortcut = 'KEYBOARD_SHORTCUT',
  ChangelogSeen = 'CHANGELOG_SEEN',
  BlockHintAction = 'BLOCK_HINT_ACTION',
  CommandMenuRun = 'COMMAND_MENU_RUN',
}
