import styled from 'styled-components';

export const Section = styled.div`
  user-select: none;
`;

export const Container = styled.div<{ withSectionDivider?: boolean }>`
  padding: 6px 0;

  a {
    text-decoration: none;
    color: inherit;
  }

  ${Section}:not(:last-child) {
    ${({ theme, withSectionDivider }) =>
      withSectionDivider &&
      `
        padding-bottom: 6px;
        margin-bottom: 6px;
        border-bottom: 1px solid ${theme.color.border};
    `}
  }
`;

export const SectionLabel = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 14px;
  color: ${({ theme }) => theme.color.textGrayscale3};
  padding: 6px 14px 8px;
`;

export const ListSearch = styled.div`
  padding: 12px 10px 0 10px;
  margin-bottom: 6px;

  & input {
    height: 28px;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.color.textGrayscale2};
  }
`;
