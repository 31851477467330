import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';

function useModalPosition(
  ref: MutableRefObject<Element | null>,
): [string, 'right' | 'left', 'bottom' | 'top', boolean] {
  const [height, setHeight] = useState<string>('inherit');
  const [hDirection, setHDirection] = useState<'right' | 'left'>('right');
  const [vDirection, setVDirection] = useState<'bottom' | 'top'>('bottom');
  const [isCalculated, setIsCalculated] = useState(false);
  const waitedMs = useRef(0);

  const waitForCalculation = () => {
    if (!ref.current?.getBoundingClientRect()?.height) {
      if (waitedMs.current > 10000) {
        return;
      }

      let ms = 1;
      if (waitedMs.current > 5) {
        ms = 10;
      } else if (waitedMs.current > 100) {
        ms = 50;
      }

      waitedMs.current += ms;
      setTimeout(waitForCalculation, ms);
      return;
    }

    // Ready
    calculate();
  };

  const calculate = () => {
    const { top, bottom, right, height } = ref.current!.getBoundingClientRect();

    let containerHeight = window.innerHeight;
    let containerWidth = window.innerWidth;
    let vDirectionBottomOffset = 120;

    // Settings are different in form respond mode
    if (
      typeof document !== 'undefined' &&
      document.querySelector('.tally-app:not(.form-builder-container)')
    ) {
      const appEl = document.querySelector('.tally-app')!;
      const rect = appEl.getBoundingClientRect();

      // If the app container is smaller than the window, we need to use the app container size, otherwise we use the window size
      containerHeight = Math.min(rect.height, window.innerHeight);
      containerWidth = Math.min(rect.width, window.innerWidth);

      // The offset is smaller when we are in respond mode
      vDirectionBottomOffset = 20;
    }

    // If this a context menu of a dropdown, we need to add some extra space
    const vDirectionTopOffset = vDirectionBottomOffset + 36;

    const toTop = top - height;
    const toBottom = containerHeight - bottom;
    const toRight = containerWidth - right;

    if (vDirection === 'bottom') {
      if (toTop < 0 && toBottom < 0) {
        setHeight(
          toTop > toBottom
            ? `${top - vDirectionTopOffset}px`
            : `${height + toBottom - vDirectionBottomOffset}px`,
        );
        setVDirection(toTop > toBottom ? 'top' : 'bottom');
      } else if (toBottom < 0) {
        setHeight(toTop > vDirectionTopOffset ? 'inherit' : `${top - vDirectionTopOffset}px`);
        setVDirection('top');
      }
    }

    if (hDirection === 'right' && toRight < 0) {
      setHDirection('left');
    }

    setIsCalculated(true);
  };

  useLayoutEffect(() => {
    waitForCalculation();
  }, []);

  return [height, hDirection, vDirection, isCalculated];
}

export default useModalPosition;
