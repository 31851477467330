import { MutableRefObject, useEffect, useRef } from 'react';

function useClickOutside(ref: MutableRefObject<Element | null>, callback: () => void) {
  const mouseDownOutside = useRef(false);

  const onMouseDown = (e: MouseEvent | TouchEvent) => {
    if (!(e.target instanceof Element)) {
      return;
    }

    mouseDownOutside.current = (ref.current && ref.current.contains(e.target)) === false;
  };

  const onMouseUp = (e: MouseEvent | TouchEvent) => {
    if (!(e.target instanceof Element)) {
      return;
    }

    if (ref.current && ref.current.contains(e.target)) {
      return;
    }

    if (mouseDownOutside.current === false) {
      return;
    }

    setTimeout(callback, 1);
  };

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('touchstart', onMouseDown);
    document.addEventListener('touchend', onMouseUp);

    return function cleanup() {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchstart', onMouseDown);
      document.removeEventListener('touchend', onMouseUp);
    };
  });
}

export default useClickOutside;
