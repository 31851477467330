import { Language } from '@tallyforms/lib';
import { ChevronDown } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';

import ContextMenu, { Item, List, Section } from '@/components/context-menu';
import Input from '@/components/form/input';
import { useCountries } from '@/hooks/use-countries';
import { useKeyboardSelection } from '@/hooks/use-keyboard-selection.hook';
import { scrollListItemIntoView } from '@/utils/device';
import {
  formatPhoneNumber,
  getPhoneNumber,
  getRemainingMaskForPhoneNumber,
} from '@/utils/phone-number';
import {
  applySpecialCountrySortingOrder,
  countryCallingCodes,
  getCountryCodeFromPhoneNumber,
  getFlagCountryCode,
  getMatchingCountryCodesForPhoneNumber,
} from '@/utils/phone-number/country-code';
import {
  getCountryCodeFromPhoneNumberStorage,
  updatePhoneNumberStorage,
} from '@/utils/phone-number/storage';

import { Container, FlagContainer, ListItemCallingCode, Mask } from './styled';

interface Props {
  defaultValue: string | undefined;
  defaultCountryCode?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  language?: Language;
  onChange: (value: string) => void;
}

interface InputOptions {
  showMask: boolean;
  showSelectButton: boolean;
  selectTrigger: CountrySelectTrigger | null;
}

enum CountrySelectTrigger {
  Manual = 'MANUAL',
  Auto = 'AUTO',
}

interface CountrySelectGroup {
  options: CountrySelectOption[];
}

interface CountrySelectOption {
  code: string;
  country: string;
  callingCode: string;
  specialOrderIndex?: number;
}

const InputPhoneNumber = ({
  defaultValue,
  defaultCountryCode,
  inputProps,
  onChange: externalOnChange,
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState(defaultValue ?? '');
  const [displayValue, setDisplayValue] = useState(
    defaultValue ? formatPhoneNumber(defaultValue) : '',
  );
  const [countryCode, setCountryCode] = useState(defaultCountryCode ?? null);
  const [inputOptions, setInputOptions] = useState<InputOptions>({
    showMask: false,
    showSelectButton: !!defaultValue,
    selectTrigger: null,
  });

  const isFirstUseEffectRun = useRef(true);
  const isFirstOnFocus = useRef(true);
  const pressedKey = useRef<string>('');
  const isPossibleAutocomplete = useRef(false);
  const countrySelectListRef = useRef<HTMLDivElement | null>(null);

  const setCountryCodeFromDefaultValues = (): string | null => {
    const cachedCountryCode = inputProps?.id
      ? getCountryCodeFromPhoneNumberStorage(inputProps.id, defaultValue, defaultCountryCode)
      : null;

    if (cachedCountryCode) {
      setCountryCode(cachedCountryCode);
      return cachedCountryCode;
    }

    if (!defaultValue) {
      setCountryCode(defaultCountryCode ?? null);
      return defaultCountryCode ?? null;
    }

    const calculatedCountryCode = getCountryCodeFromPhoneNumber(defaultValue);
    setCountryCode(calculatedCountryCode);
    return calculatedCountryCode;
  };

  const countries = useCountries();
  const allCountryOptions = useMemo(() => {
    const options: CountrySelectOption[] = [];

    for (const code in countries) {
      options.push({
        code,
        country: countries[code],
        callingCode: countryCallingCodes[code],
      });
    }

    return options.sort((a, b) => a.country.localeCompare(b.country));
  }, [countries]);

  const getAsYouTypeOptions = (input: string): CountrySelectOption[] => {
    if (input === '' || input === '+') {
      return [];
    }

    let aytOptions = allCountryOptions
      .filter((x) => x.callingCode.startsWith(input))
      .sort((a, b) => (a.callingCode > b.callingCode ? 1 : -1));

    // Some inputs have special order based on usage
    aytOptions = applySpecialCountrySortingOrder(aytOptions, input);

    return aytOptions;
  };

  const getMatchingCallingCodeOptions = (input: string): CountrySelectOption[] => {
    if (input === '' || input === '+') {
      return [];
    }

    const countryCodes = getMatchingCountryCodesForPhoneNumber(value);
    if (countryCodes.length === 0) {
      return [];
    }

    let matchingOptions = allCountryOptions
      .filter((x) => countryCodes.includes(x.code))
      .sort((a, b) => (a.callingCode > b.callingCode ? 1 : -1));

    // Some inputs have special order based on usage
    matchingOptions = applySpecialCountrySortingOrder(matchingOptions, input);

    return matchingOptions;
  };

  const countrySelectOptions = useMemo((): CountrySelectGroup[] => {
    const groups: CountrySelectGroup[] = [];

    let suggestionOptions: CountrySelectOption[] = [];
    let restOptions: CountrySelectOption[] = [];

    // If the user has typed something, surface countries that match the input on top of the list
    const aytOptions = getAsYouTypeOptions(value);

    suggestionOptions = aytOptions;

    // If no as you type options were found or the user manually brought up the select, show all countries
    if (aytOptions.length === 0 || inputOptions.selectTrigger === CountrySelectTrigger.Manual) {
      // If we don't have as you type options, show the countries that match the calling code on top of the list
      let matchingCallingCodeOptions: CountrySelectOption[] = [];
      if (aytOptions.length === 0) {
        matchingCallingCodeOptions = getMatchingCallingCodeOptions(value);
      }

      suggestionOptions = [...aytOptions, ...matchingCallingCodeOptions];

      restOptions = allCountryOptions.filter(
        (x) =>
          ![
            ...aytOptions.map((x) => x.code),
            ...matchingCallingCodeOptions.map((x) => x.code),
          ].includes(x.code),
      );
    }

    if (suggestionOptions.length > 0) {
      groups.push({ options: suggestionOptions });
    }

    if (restOptions.length > 0) {
      groups.push({ options: restOptions });
    }

    return groups;
  }, [value, inputOptions]);

  const getDefaultPrefix = (code: string | null): string => {
    if (code && countryCallingCodes[code]) {
      return countryCallingCodes[code];
    }

    return '+';
  };

  const changeInputOptions = (options: Partial<InputOptions>) => {
    if (Object.keys(options).length === 0) {
      return;
    }

    setInputOptions({ ...inputOptions, ...options });
  };

  const onChange = (newValue: string, newCountryCode: string | null, el?: HTMLInputElement) => {
    isPossibleAutocomplete.current = false;

    // Auto-complete or pasting a phone number with country code selected
    if (newValue !== '' && newValue.startsWith('+') === false && newValue.length > 1) {
      if (countryCode) {
        // We have a country code, so prefix the number with it
        // We need to check if it has the code but without the `+` char
        const code = countryCallingCodes[countryCode];
        if (code) {
          if (!newValue.startsWith(code.substring(1))) {
            newValue = `${countryCallingCodes[countryCode] ?? ''}${newValue}`;
          } else {
            newValue = `+${newValue}`;
          }
        }
      } else {
        // We don't have a country code, so if the user changes the country next, we will prefix the number with it
        isPossibleAutocomplete.current = true;
      }
    }

    // Check if value is an arabic number
    if (/[٠-٩]/.test(newValue)) {
      newValue = newValue.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d).toString());
    }

    // Update the country code if needed
    const calculatedCountryCode = getCountryCodeFromPhoneNumber(newValue);

    if (!calculatedCountryCode) {
      // No country code found, clear the country code
      setCountryCode(null);
      newCountryCode = null;
    } else if (!newCountryCode && calculatedCountryCode) {
      // No country code set, set the new country code
      setCountryCode(calculatedCountryCode);
      newCountryCode = calculatedCountryCode;
    } else if (
      newCountryCode &&
      calculatedCountryCode &&
      newCountryCode !== calculatedCountryCode &&
      countryCallingCodes[newCountryCode] !== countryCallingCodes[calculatedCountryCode]
    ) {
      // Country code and calling code are different, set the new country code
      setCountryCode(calculatedCountryCode);
      newCountryCode = calculatedCountryCode;
    } else if (newCountryCode !== countryCode) {
      setCountryCode(newCountryCode);
    }

    // Format the phone number for display
    const newDisplayValue = formatPhoneNumber(newValue, newCountryCode);

    // If the change comes from the input, check if we need to adjust the cursor position
    if (el) {
      const caret = el.selectionStart || 0;
      let adjustCaret: number | null = null;

      // Cursor needs to be adjusted for the formatting
      if (newValue !== newDisplayValue && newValue.length !== caret) {
        if (caret === 0) {
          // If the cursor is at the beginning, we need to adjust after the `+` char
          adjustCaret = 1;
        } else if (newValue.slice(0, caret) === newDisplayValue.slice(0, caret)) {
          // Everything before the caret is the same

          // We are deleting a non-number char
          if (pressedKey.current === 'Delete' && isNaN(parseFloat(newDisplayValue.charAt(caret)))) {
            adjustCaret = caret + 1;
          } else {
            adjustCaret = caret;
          }
        } else if (newValue.charAt(caret - 1) !== newDisplayValue.charAt(caret - 1)) {
          if (isNaN(parseFloat(pressedKey.current))) {
            // Typing a non-number character
            adjustCaret = caret;
          } else if (isNaN(parseFloat(newDisplayValue.charAt(caret - 1)))) {
            // Added a non-number character to the formatted value
            adjustCaret = caret + 1;
          }
        }
      }

      if (adjustCaret !== null) {
        window.requestAnimationFrame(() => {
          el.selectionStart = adjustCaret;
          el.selectionEnd = adjustCaret;
        });
      }
    }

    // Update the value
    newValue = getPhoneNumber(newDisplayValue);
    setDisplayValue(newDisplayValue);
    setValue(newValue);
    externalOnChange(newValue);

    // Save the input value to local storage
    if (inputProps?.id) {
      updatePhoneNumberStorage(inputProps.id, newValue, newCountryCode);
    }

    const aytOptions = getAsYouTypeOptions(newValue);

    // If there are more than 1 AYT matches, open the country select
    if (!isSelectOpen && newCountryCode && aytOptions.length > 1) {
      toggleSelectionMenu(true);
      changeInputOptions({
        selectTrigger: CountrySelectTrigger.Auto,
      });
    }

    // If the country select is open, check if we need to close it
    if (isSelectOpen && inputOptions.selectTrigger === CountrySelectTrigger.Auto) {
      if (
        // No country code in the phone number
        !newCountryCode ||
        // No AYT matches
        aytOptions.length === 0
      ) {
        toggleSelectionMenu(false);
      }
    }
  };

  const onCountryChange = (code: string) => {
    if (!countryCallingCodes[code]) {
      return;
    }

    if (isPossibleAutocomplete.current) {
      // Last action was possibly autocomplete, prepend country code to the phone number
      onChange(`${countryCallingCodes[code]}${value.replace('+', '')}`, code);
      isPossibleAutocomplete.current = false;
    } else if (
      countryCode &&
      countryCallingCodes[countryCode] &&
      value.length > countryCallingCodes[code].length
    ) {
      // Replace old country code with new one
      onChange(value.replace(countryCallingCodes[countryCode], countryCallingCodes[code]), code);
    } else if (!countryCode && value.length > countryCallingCodes[code].length) {
      // Prepend country code to the phone number
      onChange(`${countryCallingCodes[code]}${value.replace('+', '')}`, code);
    } else {
      // No phone number, just set the country code
      onChange(countryCallingCodes[code], code);
    }

    toggleSelectionMenu(false);

    ref.current?.focus();
  };

  useEffect(() => {
    let resultCountryCode: string | null = null;

    if (isFirstUseEffectRun.current) {
      isFirstUseEffectRun.current = false;
      resultCountryCode = setCountryCodeFromDefaultValues();

      if (defaultValue && resultCountryCode) {
        changeInputOptions({ showSelectButton: true });
      }
    }

    // Different value is pushed from the parent
    if (defaultValue !== value) {
      setValue(defaultValue ?? '');
      setDisplayValue(defaultValue ? formatPhoneNumber(defaultValue) : '');
      resultCountryCode = setCountryCodeFromDefaultValues();
    }

    // Show the select button if the country code is set
    if (defaultValue && resultCountryCode && !inputOptions.showSelectButton) {
      changeInputOptions({ showSelectButton: true });
    }
  }, [defaultValue]);

  const listOfOptions = ([] as CountrySelectOption[]).concat(
    ...countrySelectOptions.map((x) => x.options),
  );

  const { isSelectOpen, onKeyDown, selectedIndex, toggleSelectionMenu } = useKeyboardSelection({
    optionsSize: listOfOptions.length - 1,
    onSelection: (index) => {
      onCountryChange(listOfOptions[index].code);
    },
  });

  useEffect(() => {
    if (selectedIndex !== null) {
      scrollListItemIntoView(
        countrySelectListRef.current?.parentElement,
        listOfOptions[selectedIndex].code,
      );
    }
  }, [selectedIndex]);

  return (
    <Container hasCountryCode={!!countryCode}>
      <Input
        {...inputProps}
        ref={ref}
        type="tel"
        value={displayValue}
        onFocus={() => {
          let firstFocusCountryCode: string | null = null;

          // It's the first focus and we don't have a country code, try to restore it from local storage
          if (isFirstOnFocus.current) {
            isFirstOnFocus.current = false;
            firstFocusCountryCode = setCountryCodeFromDefaultValues();
          }

          if (isSelectOpen) {
            return;
          }

          if (!inputOptions.showMask) {
            changeInputOptions({ showMask: true, showSelectButton: true });
          }

          if (displayValue === '') {
            setTimeout(
              () => setDisplayValue(getDefaultPrefix(firstFocusCountryCode ?? countryCode)),
              10,
            );
          }
        }}
        onBlur={() => {
          if (isSelectOpen) {
            return;
          }

          const newInputOptions: Partial<InputOptions> = {};

          if (inputOptions.showMask) {
            newInputOptions.showMask = false;
          }

          if (displayValue === '+' || displayValue === getDefaultPrefix(countryCode)) {
            setDisplayValue('');

            if (value !== '') {
              setValue('');
              externalOnChange('');
            }

            newInputOptions.showSelectButton = false;
          }

          changeInputOptions(newInputOptions);
        }}
        onKeyDown={onKeyDown}
        onChange={(e) => onChange(e.target.value, countryCode, e.target)}
      />

      {inputOptions.showMask && (
        <Mask>
          <span>{displayValue}</span>
          <span>{getRemainingMaskForPhoneNumber(displayValue, countryCode)}</span>
        </Mask>
      )}

      {inputOptions.showSelectButton && (
        <FlagContainer
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => {
            toggleSelectionMenu(true);
            changeInputOptions({
              selectTrigger: CountrySelectTrigger.Manual,
            });
          }}>
          {countryCode ? (
            <Flag country={getFlagCountryCode(countryCode)} size={16} />
          ) : (
            <div>{t('label.country')}</div>
          )}
          <ChevronDown size={16} />
        </FlagContainer>
      )}

      {isSelectOpen && (
        <ContextMenu
          position={{ top: 40, left: 0, bottom: 40, right: 0 }}
          width="auto"
          onClose={() => {
            toggleSelectionMenu(false);
            ref.current?.focus();
          }}>
          <List ref={countrySelectListRef} withSectionDivider={true}>
            {countrySelectOptions.map(({ options }, index) => (
              <Section key={`group_${index}`}>
                {options.map(({ code, callingCode, country }) => {
                  const classNames = ['list-item'];
                  if (selectedIndex !== null && code === listOfOptions[selectedIndex].code) {
                    classNames.push('selected');
                  }

                  return (
                    <Item
                      key={code}
                      id={code}
                      className={classNames.join(' ')}
                      label={country}
                      actionContent={<ListItemCallingCode>{callingCode}</ListItemCallingCode>}
                      onClick={() => onCountryChange(code)}
                    />
                  );
                })}
              </Section>
            ))}
          </List>
        </ContextMenu>
      )}
    </Container>
  );
};

export default InputPhoneNumber;
