import { useLayoutEffect } from 'react';

import { isTouchDevice } from '@/utils/device';

function useLockBodyScroll() {
  useLayoutEffect(() => {
    const preventLockingScroll =
      // If it's a touch device, do nothing
      isTouchDevice() ||
      // If the scroll is already locked, do nothing
      document.body.classList.contains('no-scroll') ||
      // Or the page doesn't have enough height for scroll
      document.body.scrollHeight <= document.body.clientHeight;

    const scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Prevent scrolling on mount
    if (!preventLockingScroll) {
      document.body.classList.add('no-scroll');
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
      document.body.style.top = `-${scrollTop}px`;
    }

    // Re-enable scrolling when component unmounts
    return () => {
      if (!preventLockingScroll) {
        document.body.classList.remove('no-scroll');
        document.body.style.position = 'static';
        document.body.style.overflowY = 'auto';
        document.body.style.top = '0';
        document.documentElement.scrollTop = scrollTop;
      }
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
}

export default useLockBodyScroll;
