import { SubscriptionPlan } from '@tallyforms/lib';
import { useMemo } from 'react';

import Badge from '@/components/badge';

type Props = {
  plan: SubscriptionPlan;
};

const SubscriptionPlanBadge = ({ plan }: Props) => {
  const color = useMemo(() => {
    if (plan === SubscriptionPlan.Pro) {
      return 'main';
    }

    if ([SubscriptionPlan.Business, SubscriptionPlan.Lifetime].includes(plan)) {
      return 'accent';
    }

    return 'gray';
  }, [plan]);

  const text = useMemo(() => {
    if (plan === SubscriptionPlan.Pro) {
      return 'Pro';
    }

    if ([SubscriptionPlan.Business, SubscriptionPlan.Lifetime].includes(plan)) {
      return 'Business';
    }

    return 'Free';
  }, [plan]);

  return <Badge color={color}>{text}</Badge>;
};

export default SubscriptionPlanBadge;
