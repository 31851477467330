import { useEffect, useMemo } from 'react';

import { Event } from '@/types/tracking-events';
import { getKeyboardEventKey } from '@/utils/device';

interface ShortcutPayload {
  action: () => void;
  config?: Config;
}

interface Config {
  conditional?: () => boolean;
  preventDefault?: boolean;
  logFn?: (event: string, payload: any) => void;
  logPrefix?: string;
}

function useKeyboardShortcuts(
  shortcutsMap: {
    [key: string]: ShortcutPayload | (() => void);
  },
  deps?: any,
  config?: Config,
) {
  const shortcuts = useMemo(() => Object.keys(shortcutsMap), deps);
  const onlyCommandKeys = useMemo(
    () =>
      shortcuts.every(
        (x) => x.indexOf('cmd') !== -1 || x.indexOf('ctrl') !== -1 || x.indexOf('alt') !== -1,
      ),
    deps,
  );

  const onKeyDown = (e: KeyboardEvent) => {
    // All shortcuts require a commands key
    if (onlyCommandKeys && !e.ctrlKey && !e.metaKey && !e.altKey) {
      return;
    }

    let shortcut = '';
    if (e.metaKey) {
      shortcut += 'cmd+';
    }
    if (e.ctrlKey) {
      shortcut += 'ctrl+';
    }
    if (e.shiftKey) {
      shortcut += 'shift+';
    }
    if (e.altKey) {
      shortcut += 'alt+';
    }
    shortcut += getKeyboardEventKey(e);

    if (!shortcuts.includes(shortcut) || typeof shortcutsMap[shortcut] === 'undefined') {
      return;
    }

    let shortcutConfig: Config | undefined;
    let action = () => {
      // ...
    };
    if (typeof shortcutsMap[shortcut] === 'function') {
      action = shortcutsMap[shortcut] as () => void;
    } else if (typeof shortcutsMap[shortcut] === 'object') {
      action = (shortcutsMap[shortcut] as ShortcutPayload).action;
      shortcutConfig = (shortcutsMap[shortcut] as ShortcutPayload).config;
    }

    // Run the action if the conditional returns true (check global and per shortcut configs)
    if (config?.conditional && !config.conditional()) {
      return;
    }
    if (shortcutConfig?.conditional && !shortcutConfig.conditional()) {
      return;
    }

    // Prevent default if configured to do so
    if (config?.preventDefault || shortcutConfig?.preventDefault) {
      e.preventDefault();
    }

    // Execute the action
    action();

    // Log the action
    const logFn = config?.logFn || shortcutConfig?.logFn;
    if (logFn) {
      const logPrefix = shortcutConfig?.logPrefix || config?.logPrefix || '';
      logFn(Event.KeyboardShortcut, { code: `${logPrefix}${shortcut}` });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, deps);
}

export default useKeyboardShortcuts;
