import { KeyboardEvent, useRef, useState } from 'react';

interface InputOptions {
  isSelectOpen: boolean;
  selectedIndex: number | null;
}

interface Options {
  optionsSize: number;
  onSelection: (index: number) => void;
}

interface UseKeyboardSelectionHook {
  toggleSelectionMenu: (open: boolean) => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  isSelectOpen: boolean;
  selectedIndex: number | null;
}

export const useKeyboardSelection = ({
  optionsSize,
  onSelection,
}: Options): UseKeyboardSelectionHook => {
  const pressedKey = useRef<string>('');

  const [inputOptions, setInputOptions] = useState<InputOptions>({
    isSelectOpen: false,
    selectedIndex: null,
  });

  const { isSelectOpen, selectedIndex } = inputOptions;

  const toggleSelectionMenu = (open: boolean) => {
    changeInputOptions({ isSelectOpen: open });
  };

  const onSelectKeyDown = () => {
    if (selectedIndex !== null) {
      onSelection(selectedIndex);
      changeInputOptions({ isSelectOpen: false });
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    pressedKey.current = e.key;

    // Tabbing away from the input, close the country select if open
    if (isSelectOpen && e.key === 'Tab') {
      changeInputOptions({ isSelectOpen: false });
      return;
    }

    // We care about key down events only if the select is open
    if (!isSelectOpen) {
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp': {
        e.preventDefault();

        let index = selectedIndex ?? -1;

        if (e.key === 'ArrowDown') {
          index++;
          if (index > optionsSize) {
            index = 0;
          }
        } else if (e.key === 'ArrowUp') {
          index--;
          if (index < 0) {
            index = optionsSize;
          }
        }

        changeInputOptions({
          selectedIndex: index,
        });
        break;
      }

      case 'Enter':
        e.preventDefault();
        onSelectKeyDown();
        break;

      case ' ':
        if (selectedIndex) {
          e.preventDefault();
          onSelectKeyDown();
        }
        break;

      case 'Backspace':
      case 'Delete':
        if (selectedIndex) {
          changeInputOptions({ selectedIndex: null });
        }
        break;
    }
  };

  const changeInputOptions = (options: Partial<InputOptions>) => {
    if (Object.keys(options).length === 0) {
      return;
    }

    if (typeof options.isSelectOpen !== 'undefined' && !options.isSelectOpen) {
      options.selectedIndex = null;
    }
    setInputOptions({ ...inputOptions, ...options });
  };

  return {
    toggleSelectionMenu,
    onKeyDown,
    isSelectOpen,
    selectedIndex,
  };
};
