import { breakpoint, withDefaultFontFamily } from '@tallyforms/ui';
import styled from 'styled-components';

export const Container = styled.div<{
  isReady: boolean;
  width?: string;
  height: string;
  vDirection: 'bottom' | 'top';
  hDirection: 'right' | 'left';
  position: { top: number; bottom: number; left: number; right: number };
  disableScroll?: boolean;
}>`
  position: absolute;
  z-index: 1000000005;
  ${({ position, vDirection }) =>
    vDirection === 'bottom' ? `top: ${position.top}px;` : `bottom: ${position.bottom}px;`}
  ${({ position, hDirection }) =>
    hDirection === 'right' ? `left: ${position.left}px;` : `right: ${position.right}px;`}
  width: ${({ width }) => width ?? '220px'};
  max-width: 700px;
  max-height: ${({ height }) => height};
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.textGrayscale5};
  box-shadow: ${({ theme }) => theme.color.menuBoxShadow};
  font-family: ${withDefaultFontFamily()};

  ${({ isReady }) =>
    !isReady &&
    `
    visibility: hidden;
  `}

  ${({ disableScroll }) =>
    disableScroll &&
    `
    height: auto;
    max-height: initial;
    overflow: visible;
  `}

  @media (max-width: ${breakpoint.md}) {
    max-width: min(700px, calc(100vw - 2 * 25px));
  }

  @media (max-width: ${breakpoint.sm}) {
    max-width: min(700px, calc(50vw - 10px));
  }
`;

export const Content = styled.div<{ height: string; disableScroll?: boolean }>`
  max-height: ${({ height }) => height};
  overflow: hidden auto;
  background-color: ${({ theme }) => theme.color.menuBackground};
  border-radius: 8px;

  ${({ disableScroll }) =>
    disableScroll &&
    `
    height: auto;
    max-height: initial;
    overflow: visible;
  `}
`;

export const OutsideOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: 1000000005;
`;
