import { Country, getCountries } from '@tallyforms/lib';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useCountries = () => {
  const { t } = useTranslation();

  const countries = useMemo(() => getCountries(t), [t('json.countries')]);

  return countries as Record<Country, string>;
};
