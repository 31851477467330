import { forwardRef } from 'react';

import { Container, Section, SectionLabel } from './styled';

export { Section, SectionLabel };

interface Props {
  withSectionDivider?: boolean;
  children?: React.ReactNode;
}

const List = forwardRef(({ withSectionDivider, children }: Props, forwardedRef: any) => {
  return (
    <Container ref={forwardedRef} withSectionDivider={withSectionDivider}>
      {children}
    </Container>
  );
});

List.displayName = 'List';

export default List;
