import styled from 'styled-components';

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: 16px;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  .badge {
    margin-left: ${({ theme }) => theme.spacing.sm};
  }
`;

export const Container = styled.div<{
  hasNoAction?: boolean;
  isSecondary?: boolean;
  fullWidth?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ fullWidth = false }) => (fullWidth ? 'width: 100%' : 'width: calc(100% - 8px);')};
  height: 28px;
  margin: 0 4px;
  padding: 0 10px;
  color: ${({ theme }) => theme.color.text};
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px;
  cursor: ${({ hasNoAction }) => (!hasNoAction ? 'pointer' : 'default')};
  user-select: none;
  white-space: nowrap;

  svg {
    color: ${({ theme }) => theme.color.text};
    min-width: 16px;
    min-height: 16px;
  }

  ${LabelContainer} svg {
    color: ${({ theme }) => theme.color.textGrayscale3};
  }

  ${({ theme, isSecondary }) =>
    isSecondary &&
    `
    color: ${theme.color.textGrayscale3};

    ${LabelContainer} svg {
      color: ${theme.color.textGrayscale4};
    }
  `}

  ${({ theme, hasNoAction, isSecondary }) =>
    !hasNoAction &&
    `
    @media (hover: hover) {
      &:hover {
        background-color: ${theme.color.actionBackgroundHover};

        svg {
          color: ${theme.color.text};
        }

        ${
          isSecondary &&
          `
          color: ${theme.color.textGrayscale1};

          svg {
           color: ${theme.color.textGrayscale2};
          }
        `
        }
      }
    }
  `}

  &.selected {
    background-color: ${({ theme }) => theme.color.actionBackgroundHover};

    svg {
      color: ${({ theme }) => theme.color.text};
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;

    @media (hover: hover) {
      &:hover {
        background-color: transparent;

        svg {
          color: ${({ theme }) => theme.color.textGrayscale3};
        }
      }
    }
  }
`;
