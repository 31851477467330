import { Language, ValidationRuleConstraint } from '@tallyforms/lib';

import Input from '@/components/form/input';
import InputPhoneNumber from '@/components/form/input/phone-number';
import ValidationError from '@/components/form/validation-error';
import { Question } from '@/types/form-builder';

import { Container, Content } from './styled';

interface Props {
  value?: string;
  placeholder?: string;
  isRequired?: boolean;
  internationalFormat?: boolean;
  defaultCountryCode?: string;
  error?: ValidationRuleConstraint;
  question?: Question;
  language?: Language;
  onChange?: (value: string) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const InputPhoneNumberBlock = ({
  value = '',
  placeholder = '',
  isRequired,
  internationalFormat,
  defaultCountryCode,
  error,
  question,
  language,
  onChange,
  renderRequiredIndicator,
}: Props) => {
  const errorId = error ? `error_${question?.blockGroupUuid}` : undefined;

  if (!internationalFormat) {
    return (
      <Container>
        <Content>
          <Input
            id={question?.blockGroupUuid}
            type="tel"
            value={value}
            placeholder={placeholder}
            required={isRequired}
            autoComplete="tel"
            aria-label={question?.title ?? undefined}
            aria-required={isRequired ? 'true' : 'false'}
            aria-invalid={error ? 'true' : 'false'}
            aria-describedby={errorId}
            onChange={(e) => onChange?.(e.target.value)}
          />
          {renderRequiredIndicator && renderRequiredIndicator()}
          {error && <ValidationError id={errorId} error={error} />}
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <InputPhoneNumber
          defaultValue={value}
          defaultCountryCode={defaultCountryCode}
          language={language}
          inputProps={{
            id: question?.blockGroupUuid,
            placeholder,
            required: isRequired,
            autoComplete: 'tel',
            'aria-label': question?.title ?? undefined,
            'aria-required': isRequired ? 'true' : 'false',
            'aria-invalid': error ? 'true' : 'false',
            'aria-describedby': errorId,
          }}
          onChange={(v) => onChange?.(v)}
        />
        {renderRequiredIndicator && renderRequiredIndicator()}
        {error && <ValidationError id={errorId} error={error} />}
      </Content>
    </Container>
  );
};

export default InputPhoneNumberBlock;
