import { withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

export const FlagContainer = styled.div`
  position: absolute;
  top: 0;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  cursor: pointer;
  border: 0;
  border-radius: 5px;

  ${({ theme }) => withDirection('right', '0', theme.direction)}
  ${({ theme }) => theme.direction === 'rtl' && `flex-direction: row-reverse;`}

  > svg {
    ${({ theme }) => withDirection('margin-left', '6px', theme.direction)}
    pointer-events: none;
    color: ${({ theme }) => theme.color.textGrayscale3};
  }

  > div {
    font-size: 13px;
    color: ${({ theme }) => theme.color.textGrayscale2};
  }

  @media (hover: hover) {
    &:hover {
      > div {
        color: ${({ theme }) => theme.color.textGrayscale1};
      }
    }
  }
`;

export const Container = styled.div<{ hasCountryCode: boolean }>`
  position: relative;
  direction: ltr;

  .tally-context-menu {
    width: 100%;
    max-height: 400px;
  }

  ${({ theme, hasCountryCode }) =>
    hasCountryCode === false &&
    `
    input {
      ${withDirection('padding-right', '82px', theme.direction)};
      ${theme.direction === 'rtl' && `text-align: right;`};
    }

    ${FlagContainer} {
      background-color: transparent;
    }
  `}

  ${({ hasCountryCode, theme }) =>
    hasCountryCode === true &&
    `
    input {
      ${withDirection('padding-right', '50px', theme.direction)};
      ${theme.direction === 'rtl' && `text-align: right;`};
    }
  `}
`;

export const Mask = styled.div`
  position: absolute;
  top: 0;
  pointer-events: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.color.inputPlaceholder};

  span:nth-child(1) {
    color: transparent;
  }

  span:nth-child(2) {
    font-size: 19px;
  }

  ${({ theme }) => theme.direction === 'rtl' && `display: none;`}
`;

export const ListItemCallingCode = styled.div`
  color: ${({ theme }) => theme.color.textGrayscale2};
  padding-left: ${({ theme }) => theme.spacing.sm};
`;
