import { getExample, parsePhoneNumber } from 'awesome-phonenumber';

import {
  getCountryCodeFromPhoneNumber,
  getMatchingCountryCodesForPhoneNumber,
} from '@/utils/phone-number/country-code';

const MASK_CHAR = '_';

export const getMaskForPhoneNumber = (phoneNumber: string, countryCode: string | null): string => {
  // Default mask
  let mask = (phoneNumber.length < 3 ? '+## ### ## ## ##' : '+### ## ### ###').replace(
    /#/g,
    MASK_CHAR,
  );

  if (!countryCode) {
    countryCode = getCountryCodeFromPhoneNumber(phoneNumber);
  }

  if (countryCode) {
    try {
      const pn = parsePhoneNumber(phoneNumber, { regionCode: countryCode });
      let type = pn.type;
      if (!type || type === 'unknown') {
        type = 'mobile';
      }

      mask = getExample(countryCode, type).number!.international.replace(/[0-9]/g, MASK_CHAR);
    } catch (_e) {
      // Ignore
    }
  }

  if (!countryCode) {
    return '+###'.replace(/#/g, MASK_CHAR);
  }

  return mask;
};

export const getRemainingMaskForPhoneNumber = (
  phoneNumber: string,
  countryCode: string | null,
): string => {
  return getMaskForPhoneNumber(phoneNumber, countryCode).substring(phoneNumber.length);
};

export const formatPhoneNumber = (
  phoneNumber: string,
  countryCode: string | null = null,
): string => {
  const mask = getMaskForPhoneNumber(phoneNumber, countryCode);

  const justNumbersStr = phoneNumber.replace(/[^0-9]/g, '');

  let formattedResult = '';
  let numIndex = 0;
  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === MASK_CHAR) {
      formattedResult += justNumbersStr[numIndex] || '';
      numIndex++;

      if (numIndex >= justNumbersStr.length) {
        break;
      }
    } else {
      formattedResult += mask[i];
    }
  }

  if (numIndex < justNumbersStr.length) {
    formattedResult += justNumbersStr.substring(numIndex);
  }

  return formattedResult;
};

export const getPhoneNumber = (phoneNumber: string): string => {
  const numberStr = phoneNumber.replace(/[^0-9]/g, '');
  if (numberStr.length === 0) {
    return '';
  }

  const fallbackNumber = `+${numberStr}`;

  const countryCode = getCountryCodeFromPhoneNumber(phoneNumber);
  if (!countryCode) {
    return fallbackNumber;
  }

  try {
    const pn = parsePhoneNumber(phoneNumber, { regionCode: countryCode });
    return pn.number?.e164 ?? fallbackNumber;
  } catch (_e) {
    return fallbackNumber;
  }
};

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
  const numberStr = phoneNumber.replace(/[^0-9]/g, '');
  if (numberStr.length === 0) {
    return false;
  }

  const countryCodes = getMatchingCountryCodesForPhoneNumber(phoneNumber);
  if (countryCodes.length === 0) {
    return false;
  }

  let isValid = false;

  try {
    for (const code of countryCodes) {
      const pn = parsePhoneNumber(phoneNumber, { regionCode: code });
      if (pn.valid) {
        isValid = true;
        break;
      }
    }
  } catch (_e) {
    // Ignore
  }

  return isValid;
};
