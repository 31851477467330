import { Position } from '@tallyforms/lib';
import { Fragment, MouseEvent, ReactNode, useEffect, useRef } from 'react';

import Tooltip from '@/components/tooltip';
import useClickOutside from '@/hooks/use-click-outside';
import useKeyboardShortcuts from '@/hooks/use-keyboard-shortcuts';
import useLockBodyScroll from '@/hooks/use-lock-body-scroll';
import useModalPosition from '@/hooks/use-modal-position';
import { getPositionForContextMenuByTrigger } from '@/utils/dom';

import Item from './item';
import List, { Section, SectionLabel } from './list';
import { Container, Content, OutsideOverlay } from './styled';

export { Item, List, Section, SectionLabel };

interface Props {
  children: ReactNode;
  id?: string;
  trigger?: HTMLElement;
  position?: Position;
  width?: string;
  disableClickOutside?: boolean;
  disableScroll?: boolean;
  disableOutsideOverlay?: boolean;
  rtl?: boolean;
  onClose: () => void;
  setMenuHeight?: (height: string) => void;
  onMouseDown?: (e: MouseEvent<HTMLDivElement>) => void;
}

const ContextMenu = ({
  children,
  id,
  trigger,
  position,
  width,
  disableClickOutside,
  disableScroll,
  disableOutsideOverlay,
  rtl,
  onClose,
  setMenuHeight,
  onMouseDown,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const calculatedPosition = trigger
    ? getPositionForContextMenuByTrigger(trigger)
    : {
        top: Infinity,
        right: Infinity,
        bottom: Infinity,
        ...position!,
        left: position?.left
          ? rtl
            ? position.left - (ref.current?.getBoundingClientRect().width || 0)
            : position.left
          : Infinity,
      };

  const [height, hDirection, vDirection, isCalculated] = useModalPosition(ref);

  useClickOutside(ref, () => !disableClickOutside && onClose());

  useKeyboardShortcuts({
    Escape: () => !disableClickOutside && onClose(),
  });

  useLockBodyScroll();

  useEffect(() => {
    Tooltip.hide();
  }, []);

  useEffect(() => {
    setMenuHeight?.(height);
  }, [height]);

  return (
    <Fragment>
      {!disableOutsideOverlay && <OutsideOverlay className="tally-context-menu-overlay" />}
      <Container
        ref={ref}
        id={id}
        className="tally-context-menu"
        isReady={isCalculated}
        width={width}
        height={height}
        vDirection={vDirection}
        hDirection={hDirection}
        position={calculatedPosition}
        disableScroll={disableScroll}
        onClick={(e: any) => e.stopPropagation()}>
        <Content height={height} disableScroll={disableScroll} onMouseDown={onMouseDown}>
          {children}
        </Content>
      </Container>
    </Fragment>
  );
};

export default ContextMenu;
