import { SubscriptionPlan } from '@tallyforms/lib';
import { LucideIcon } from 'lucide-react';
import Link from 'next/link';

import SubscriptionPlanBadge from '@/components/badge/subscription-plan';

import { Container, LabelContainer } from './styled';

interface Props {
  id?: string;
  className?: string;
  icon?: LucideIcon | undefined;
  label?: string;
  actionContent?: React.ReactNode;
  href?: string;
  as?: string;
  rel?: string;
  target?: string;
  disableNextLink?: boolean;
  hasNoAction?: boolean;
  isSecondary?: boolean;
  fullWidth?: boolean;
  requiresPlan?: SubscriptionPlan;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
}

const Item = ({
  id,
  className,
  icon,
  label,
  actionContent,
  href,
  as,
  rel,
  target,
  disableNextLink,
  hasNoAction,
  isSecondary,
  fullWidth,
  requiresPlan,
  onClick,
  onDoubleClick,
  onMouseDown,
}: Props) => {
  const Icon = icon ?? null;

  const renderContent = () => {
    return (
      <Container
        id={id}
        className={className}
        hasNoAction={hasNoAction}
        isSecondary={isSecondary}
        fullWidth={fullWidth}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onMouseDown={onMouseDown}>
        <LabelContainer title={label}>
          {!!Icon && <Icon size={16} />}
          {!!label && <span>{label}</span>}
          {requiresPlan && <SubscriptionPlanBadge plan={requiresPlan} />}
        </LabelContainer>

        {!!actionContent && actionContent}
      </Container>
    );
  };

  // Render a link if href is provided
  if (href) {
    // Regular a tag
    if (disableNextLink) {
      return (
        <a
          href={href}
          rel={rel}
          target={target}
          className={className}
          onClick={onClick}
          onDoubleClick={onDoubleClick}>
          {renderContent()}
        </a>
      );
    }

    // Next.js link
    return (
      <Link
        href={href}
        as={as}
        rel={rel}
        target={target}
        prefetch={false}
        className={className}
        onClick={onClick}
        onDoubleClick={onDoubleClick}>
        {renderContent()}
      </Link>
    );
  }

  // Otherwise, render a div with the content
  return renderContent();
};

export default Item;
